/* app/on-demand/page/homepage */

define(
	[
		'jquery',
		'app/ui/watch/livestream',
		'app/on-demand/elements/show-more',
		'app/on-demand/elements/tabs',
		'app/on-demand/elements/accordion',
		'magnificPopup'
	],

	function ($, Livestream, ShowMore, Tabs, Accordion) {

		'use strict';
		ShowMore.init();
		Tabs.init();
		Accordion.init();

		var $playerElem = $('#myPlayer');
		var thumbnailUrl = $playerElem.data('thumbnailUrl');

		var livestreamOptions = { displayTitle: false };
		if (thumbnailUrl !== undefined) {
			livestreamOptions.thumbnail = thumbnailUrl;
		}
		Livestream.init(livestreamOptions);
	}
);