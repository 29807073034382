/* app/on-demand/elements/tabs */

/**
 * Allows an easy way to expand and collapse tabs.
 * The trigger element needs the class "js-tab-trigger".
 * All elements with that class will automatically have triggers applied.
 * The triggers will be activated on click only.
 * 
 * All trigger elements need a "hide" data attribute with a selector for the element(s) it hides,
 * and a "show" data attribute with a selector for the element it shows.
 * (e.g. data-hide="#tab1,#tab2" data-show="#tab3")
 *
 * All collapsible elements need a "trigger" data attribute with a selector for the element that shows it.
 * (e.g. data-trigger="#clickable-element")
 * 
 * All tabs which are to be closed by default should have the "tab__content--hidden" class applied to the content,
 * and the "tab--closed" class applied to the trigger element.
 * The tab which should be open by default should have the "tab--open" class applied to the trigger element.
 * There should always be only one tab for a given set of tabs without this class. This will be open
 * by default.
 * 
 * e.g.
 * <div class="tabs">
 * 	<button id="details-tab-trigger" class="tab tab--open js-tab-trigger" data-hide="#links-tab" data-show="#details-tab" aria-expanded="true">
 * 		Details
 * 	</button>
 * 	<button id="links-tab-trigger" class="tab tab--closed js-tab-trigger" data-hide="#details-tab" data-show="#links-tab" aria-expanded="false">
 * 		Related Links
 * 	</button>
 * </div>
 * 
 * <div id="details-tab" class="tab__content details-tab" data-trigger="#details-tab-trigger">
 * 	<!-- Content Goes Here -->
 * </div>
 * <div id="links-tab" class="tab__content tab__content--hidden related-links" data-trigger="#links-tab-trigger">
 * 	<!-- Content Goes Here -->
 * </div>
 */

define(
	[
		'jquery',
		'app/ui/watch/video',
		'pubsub'
	],

	function ($, Video) {

		var Tabs;

		var selectors = {
			trigger: ".js-tab-trigger"
		};

		var classes = {
			initialised: "js-tabs-initialised",
			hiddenTab: "tab__content--hidden",
			activeTrigger: "tab--open",
			inactiveTrigger: "tab--closed"
		};

		return {

			/** Initialise the Tabs functionality.
			*
			* @method module:app/on-demand/elements/Tabs.init
			*/
			init: function () {
				Tabs = this;
				this._initEvents();
			},

			/** Initialise the Tabs functionality for AJAX requests.
			*
			* @method module:app/on-demand/elements/Tabs.initAjax
			*/
			initAjax: function () {
				var ajaxEventId = Video.id;
				$.subscribe('/ajax/ready/' + ajaxEventId, Tabs._initEvents);
			},

			_initEvents: function () {
				//Tab trigger events.
				$(selectors.trigger).each(function () {
					var $trigger = $(this);
					if (!$trigger.hasClass(classes.initialised)) {
						$trigger.on('click', Tabs._clickTabEvent);
						//Don't initialise again.
						$trigger.addClass(classes.initialised);
					}
				});
			},

			_clickTabEvent: function (event) {
				event.preventDefault();
				$trigger = $(this);

				var $showTab = $($trigger.data('show'));
				var $hideTabs = $($trigger.data('hide'));
				var isHidden = $showTab.hasClass(classes.hiddenTab);

				if (isHidden) {
					//Toggle tab visibility
					$showTab.removeClass(classes.hiddenTab);
					$hideTabs.addClass(classes.hiddenTab);
					Tabs._toggleTriggerClasses($trigger, true);

					//Toggle aria-expanded attribute
					$trigger.attr('aria-expanded', true);
					$hideTabs.each(function (i, hiddenTab) {
						$(hiddenTab.dataset['trigger']).each(function (i, hiddenTrigger) {
							$(hiddenTrigger).attr('aria-expanded', false);
							Tabs._toggleTriggerClasses($(hiddenTrigger), false)
						});
					});
				}
			},

			_toggleTriggerClasses: function ($trigger, isActive) {
				if (isActive) {
					$trigger.addClass(classes.activeTrigger);
					$trigger.removeClass(classes.inactiveTrigger);
				}
				else {
					$trigger.addClass(classes.inactiveTrigger);
					$trigger.removeClass(classes.activeTrigger);
				}
			}

		};
	}
);