/* app/on-demand/elements/accordion */

/**
 * Expansion and collapse of an accordion element. Adds an additional link for closing the accordion to
 * the bottom of the accordion container.
 * 
 * The container element needs the class "js-accordion".
 * There should be a link as a child of this container with the class "js-accordion-link".
 * An element with the class "js-accordion-content" and "accordion__content--hidden" should also be child of the container.
 * Note that the "accordion__content--hidden" is for css purposes to hide the content appropriately.
 * 
 * The link (i.e. the element with "js-accordion-link") should have the following data attributes:
 * data-show-more-text: Optional. Text for the toggle link while content is hidden. Default is "Show Accordion Content".
 * data-show-less-text: Optional. Text for the toggle link while content is showing. Default is "Hide Accordion Content".
 * 
 * e.g.
 * <div class="accordion js-accordion">
 *	<div class="accordion__bar">
 * 		<a href="#accordion" class="link--accordion js-accordion-link" data-show-more-text="@Model.ShowMoreText" data-show-less-text="@Model.ShowLessText">
 * 			@Model.ShowMoreText
 * 		</a>
 *		<h3 class="accordion__title">@Model.Title</h3>
 *	</div>
 * 	<div class="accordion__content accordion__content--hidden js-accordion-content">
 * 		<div class="accordion__content-padder">
 * 			@Model.Content
 * 		</div>
 * 	</div>
 * </div>
 */

define(
	[
		'jquery',
		'app/ui/watch/video',
		'pubsub'
	],

	function ($, Video) {

		var Accordion;

		var selectors = {
			container: ".js-accordion",
			content: ".js-accordion-content",
			link: ".js-accordion-link",
			remove: ".js-accordion-remove"
		};

		var classes = {
			initialised: "js-show-more-initialised",
			linkEnd: "link--accordion-end",
			remove: "js-accordion-remove",
			hidden: "accordion__content--hidden"
		};

		var defaults = {
			showMoreText: "Show Accordion Content",
			showLessText: "Hide Accordion Content"
		};

		return {

			/** Initialise the Accordion functionality.
			*
			* @method module:app/on-demand/elements/Accordion.init
			*/
			init: function () {
				Accordion = this;

				this._initElements();
			},

			initAjax: function () {
				var ajaxEventId = Video.id;
				$.subscribe('/ajax/ready/' + ajaxEventId, Accordion._initElements);
			},

			_initElements: function () {
				$(selectors.container).each(function () {
					var $container = $(this);
					if (!$container.hasClass(classes.initialised)) {
						$container.find(selectors.content).height(0);
						//Add link events.
						$container.on('click', selectors.link, Accordion._clickEvent);
						//Don't initialise again.
						$container.addClass(classes.initialised);
					}
				});
			},

			_constructLink: function ($template) {
				var $link = $template.clone();
				$link.addClass(classes.remove);
				$link.addClass(classes.linkEnd);
				return $link;
			},

			_clickEvent: function (event) {
				event.preventDefault();
				$link = $(this);
				$parent = $link.closest(selectors.container);

				var $content = $parent.find(selectors.content);
				var isCollapsed = $content.hasClass(classes.hidden);

				//Toggle height.
				if (isCollapsed) {
					//Expand accordion
					$content.removeClass(classes.hidden);
					$content.height($content.prop('scrollHeight'));
					//Swap initial link text.
					var showLessText = $link.data('show-less-text');
					if (!showLessText) {
						showLessText = defaults.showLessText;
					}
					$link.text(showLessText);
					//Add link at bottom.
					$parent.append(Accordion._constructLink($link));
				}
				else {
					//Remove bottom link.
					$remove = $parent.find(selectors.remove);
					$remove.remove();
					//Swap link text
					$link = $parent.find(selectors.link);
					var showMoreText = $link.data('show-more-text');
					if (!showMoreText) {
						showMoreText = defaults.showMoreText;
					}
					$link.text(showMoreText);
					//Collapse accordion
					var transitionSpeed = Accordion._parseTime($content.css('transition-duration'));
					$content.height(0);
					setTimeout(function() {
						$content.addClass(classes.hidden);
					}, transitionSpeed);
				}

				//Toggle aria-expanded attribute
				$link.attr('aria-expanded', !isCollapsed);
			},

			_parseTime: function (time) {
				var isMS = time.indexOf('ms') > -1;
				var parsedTime = parseFloat(time);
				if (!isMS) {
					parsedTime = parsedTime * 1000;
				}
				return parsedTime;
			}

		};
	}
);