/* app/ui/watch/video */

define(
	[
		'jquery',
		'util/core',
		'util/mediaqueries',
		'pubsub'
	],
	function ($, Core, MediaQueries) {

		'use strict';

		var Listing;
		var selectors = {
			videoLink: '.js-ajax-video'
		};

		var $page = null;
		var $videoContainer = null;

		return {
			id: null,

			init: function(config) {
				Listing = this;

				$page = config.pageContainer;
				$videoContainer = config.videoContainer;
				this.id = Math.floor(Math.random() * (1000 - 1) + 1);

				var excludeMediaQueries = config.excludeMediaQueries;
				if (typeof excludeMediaQueries !== 'undefined' && excludeMediaQueries == true) {
					this._initDesktopListeners();
				} else {
					MediaQueries.register([
						{
							queries: MediaQueries.queries['housing--mobile'],
							shouldDegrade: false,
							match: Listing._destoryDesktopListeners
						},
						{
							queries: MediaQueries.queries['housing--desktop'],
							shouldDegrade: true,
							match: Listing._initDesktopListeners
						}
					]);
				}
			},

			// Initialise elements
			initElements: function ($videoElm) {
				$videoContainer = $videoElm;
			},

			_initDesktopListeners: function() {
				$page.on('click', selectors.videoLink, Listing._onVideoClick);
				$.subscribe('/ajax/ready/' + Listing.id, Listing._processAjaxResponse);
			},

			_destoryDesktopListeners: function () {
				$page.off('click', selectors.videoLink, Listing._onVideoClick);
				$.unsubscribe('/ajax/ready/' + Listing.id);
			},

			// Runs on click of a video link
			// 1. Get AJAX url from data attribute
			// 2. Get original href
			// 3. Set loading state
			// 4. Publish event with url and unique id variables
			// 5. Follow href is no AJAX url found
			_onVideoClick: function () {
				event.preventDefault();
				var $link = $(this);
				var href = $link.attr('href');
				var url = $link.attr('data-ajax-url');

				if (typeof (url) !== 'undefined' && url !== '') {
					$.publish('/loader/setLoadingState', { container: $videoContainer, action: 'addClass' });
					$.publish('/ajax/get', [{ url: url, id: Listing.id, href: href }]);
				} else {
					window.location = href;
				}
			},

			// Runs when new data is returned
			// 1. Forwards ajax data on to 'ProcessAjax' module
			// 2. Scroll page back to top of listing
			_processAjaxResponse: function (response) {
				var data = {
					container: $videoContainer,
					response: response
				};
				$.publish('/processAjax/data', data);
				Core.scrollToElm($videoContainer);
			}
		};
	}
);