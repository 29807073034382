/* app/ui/watch/livestream */

define(
	[
		'jquery',
		'jwplayer',
		'pubsub'
	],

	function ($) {

		var Livestream;
		var livePlayer = null;
		var $textarea = null;
		var copyText;
		var copiedText;
		var settings = {
			thumbnail: "/content/Images/LiveBroadcast/stream_thumbnail_v2.jpg",
			englishTitle: "English only",
			mixedTitle: "House audio (English & Maori)",
			displayTitle: true,
			captions: {
				color: '#FFFFFF',
				fontSize: 18,
				backgroundOpacity: 75
			}
		};
		
		return {
			init: function (options) {
				Livestream = this;

				// Override default settings with specified user options
				if (options !== undefined) {
					for (var option in options) {
						if (option === 'captions') {
							for (var captionOption in options[option]) {
								settings[option][captionOption] = options[option][captionOption];
							}
						}
						settings[option] = options[option];
					}
				}

				// Set up jwplayer
				if (!window.jwplayer) {
					window.jwplayer = jwplayer;
				} 
				
				window.jwplayer.key = 'XKduAUFUYhMxPR1hATkrogB+CaLvckhjnp3QdebKQuY=';
				Livestream._initPlayer();
				Livestream._initCopyEmbedCode();
			},

			_initPlayer: function() {

				Livestream.destroyElms();

				// Convert playlist
				var playlist = Stream_Playlist.map(function(x)  
					{
						return {
							image: settings.thumbnail,
							sources: [{ file: x.Uri }],
							title: x.Title
						}
					})

				livePlayer = window.jwplayer('myPlayer').setup({
					aspectratio: '16:9',
					width: '100%',
					displaytitle: settings.displayTitle,
					playlist: playlist,
					primary: 'html5',
					captions: settings.captions
				});

				Livestream._initEvents();
			},

			_initEvents: function() {
				$('.js-ptv-popup').on('click', Livestream._openLivestreamPopup);
				$('.js-play-livestream').on('click', Livestream._playLivestream);
			},

			destroyElms: function() {
				if (livePlayer) {
					livePlayer.remove();
					livePlayer = undefined;
				}
			},

			_openLivestreamPopup: function(event) {
				event.preventDefault();
				window.open(this.href, "ptvPopup", "resizable=yes,toolbar=no,scrollbars=no,menubar=no,status=no,titlebar=no,location=no width=500, height=350");
				livePlayer.stop();
			},

			_playLivestream: function(event) {
				event.preventDefault();
				livePlayer.play(true);
			},

			_initCopyEmbedCode: function() {
				$('.js-video-embed-btn').magnificPopup({
					type: 'inline',
					midClick: true,
					mainClass: 'mfp-embed-code',
					closeBtnInside: true
				});
				
				$textarea = $('.js-embed-copy-content');
				$textarea.text($textarea.text().trim());

				var $copyButton = $('.js-copy-btn')
				if ($copyButton) {
					copyText = $copyButton.text();
					copiedText = $copyButton.attr('data-click-text');
					$copyButton.on('click', Livestream._copyTextToClipboard);
				}
			},

			_copyTextToClipboard: function() {
				var $button = $(this);
				$textarea.removeAttr('disabled');
				// iOS needs some special treatment - it doesn't allow selection the same way everything else does.
				if (navigator.userAgent.match(/ipad|iphone/i)) {
					// Don't automatically zoom in when copying.
					var $noScale = $('<meta>', { name: 'viewport', content: 'width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no' });
					$noScale.appendTo('head');
					// Select the textarea content.
					range = document.createRange();
					range.selectNodeContents($textarea[0]);
					selection = window.getSelection();
					selection.removeAllRanges();
					selection.addRange(range);
					$textarea[0].setSelectionRange(0, $textarea.text().length);
					// Allow users to control zoom again.
					$noScale.remove();
				} else {
					// Select the textarea content.
					$textarea.select();
				}
				var success = document.execCommand('copy');
				// Unselect the textarea content
				window.getSelection().removeAllRanges();
				$textarea.attr('disabled', 'disabled');

				// Set the button text to reflect success/failure.
				if (success) {
					$button.text(copiedText);
				} else {
					$button.text("Failed to copy");
				}
				setTimeout(function () {
					$button.text(copyText);
				}, 1000);
			}
		};

	}
);