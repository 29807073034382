/* app/on-demand/elements/show-more */

/**
 * Ensures a specified height is given to an element by default. If the element has hidden content,
 * it adds a link with configurable texts which will expand the height to show all content.
 * Note that the container element should have overflow set to hidden for this to function correctly.
 * 
 * The container element needs the class "js-show-more". It should be wrapped in another element with
 * the class "js-show-more-container". This will hold the content to be height-adjusted as well as the
 * toggle link.
 * 
 * The container (i.e. the element with "js-show-more") should have the following data attributes:
 * data-collapsed-height: Mandatory. Integer value for the initial height of the element.
 * data-show-more-text: Optional. Text for the toggle link while content is hidden. Default is "Show More".
 * data-show-less-text: Optional. Text for the toggle link while content is showing. Default is "Show Less".
 * 
 * e.g.
 * 
 */

define(
	[
		'jquery',
		'app/ui/watch/video',
		'pubsub'
	],

	function ($, Video) {

		var ShowMore;

		var selectors = {
			container: ".js-show-more",
			containerParent: ".js-show-more-container",
			link: ".js-show-more-link"
		};

		var classes = {
			initialised: "js-show-more-initialised",
			link: "js-show-more-link link--show-more"
		};

		var defaults = {
			showMoreText: "Show More",
			showLessText: "Show Less"
		};

		return {

			/** Initialise the ShowMore functionality.
			*
			* @method module:app/on-demand/elements/ShowMore.init
			*/
			init: function () {
				ShowMore = this;
				var ajaxEventId = Video.id;
				$.subscribe('/ajax/ready/' + ajaxEventId, ShowMore._initElements);

				this._initElements();
			},

			_initElements: function () {
				$(selectors.container).each(function () {
					var $container = $(this);
					if (!$container.hasClass(classes.initialised)) {
						var collapsedHeight = $container.data('collapsed-height');
						var scrollHeight = $container.prop('scrollHeight')

						//Only alter DOM if there's hidden text
						if (collapsedHeight < scrollHeight) {
							//Set to designated height
							$container.height(collapsedHeight);
							//Add show-more link
							$parent = $container.closest(selectors.containerParent);
							$parent.append(ShowMore._constructShowMoreLink($container));
						}

						//Don't initialise again.
						$container.addClass(classes.initialised);
					}
				});

				ShowMore._initEvents();
			},

			_constructShowMoreLink: function ($container) {
				//Link text.
				var text = $container.data('show-more-text');
				if (!text) {
					text = defaults.showMoreText;
				}

				//ID of the container - necessary for toggling functionality.
				var id = $container.attr('id');
				if (id == null || id == '') {
					id = "show-more" + Date.now();
					$container.attr('id', id);
				}

				//Full link markup
				return '<a href="#show-more" class="' + classes.link + '" data-toggle-element="#' + id + '" aria-expanded="false">' + text + '</a>';
			},

			_initEvents: function () {
				//Trigger events.
				$(selectors.link).each(function () {
					var $link = $(this);
					if (!$link.hasClass(classes.initialised)) {
						$link.on('click', ShowMore._clickEvent);
						//Don't initialise again.
						$link.addClass(classes.initialised);
					}
				});
			},

			_clickEvent: function (event) {
				event.preventDefault();
				$link = $(this);

				var $toggle = $($link.data('toggle-element'));
				var defaultHeight = $toggle.data('collapsed-height');
				var isCollapsed = $toggle.height() != defaultHeight;

				//Toggle height.
				if (isCollapsed) {
					var showMoreText = $toggle.data('show-more-text');
					if (!showMoreText) {
						showMoreText = defaults.showMoreText;
					}
					$toggle.height(defaultHeight);
					$link.text(showMoreText);
				}
				else {
					var scrollHeight = $toggle.prop('scrollHeight');
					var showLessText = $toggle.data('show-less-text');
					if (!showLessText) {
						showLessText = defaults.showLessText;
					}
					$toggle.height(scrollHeight);
					$link.text(showLessText);
				}

				//Toggle aria-expanded attribute
				$link.attr('aria-expanded', !isCollapsed);
			}

		};
	}
);